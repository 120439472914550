<template>
  <div class="bind-email">
    <div class="record-info">
        <p class="record-header">检测到您尚未绑定邮箱</p>
        <p class="tip">请先绑定您已注册过的邮箱地址</p>
          <div class="record-item">
            <input type="text" v-model="email" placeholder="请输入您已注册过的邮箱地址">
            <van-button 
              round  
              type="danger" 
              block
              :disable="!code"
              @click="bind"
            >绑定</van-button>
          </div>
      </div>
  </div>
</template>

<script>
import { bindEmail } from '../../api/user'

export default {
  data () {
    return {
      email: '',
    }
  },
 
  methods: {
    bind () { 
      bindEmail(this.email)
        .then(res => { 
          if (res.code === 0) {
            this.result = true
            this.$toast.success('绑定成功')
            setTimeout(() => { 
              location.reload()
            }, 1000)
          } else { 
            this.$toast.fail(res.message)
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/_var.scss';

.bind-email {
  padding-top: 100px;
  .success-tip {
    font-size: 18px;
    text-align: center;
    font-weight: bold;
  }
  .record-info {
    margin: -5px 0 15px;
    padding: 15px;
    border-radius: 4px;
    .record-header {
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    .tip {
      font-size: 12px;
      color: #666;
      text-align: center;
      margin-bottom: 20px;
    }
    .record-item {
      input {
        background-color: #fff;
        height: 44px;
        padding: 8px 15px;
        border: 1px solid #ddd;
        outline: none;
        border-radius: 22px;
        background-color: #f5f5f5;
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }
}
</style>